import i18next from "i18next"
import { initReactI18next } from "react-i18next"
//import LngDetector from 'i18next-browser-languagedetector';
import common_es from "./translations/es/common.json"
import common_en from "./translations/en/common.json"
import common_pt from "./translations/pt/common.json"

const options = {
  // order and from where user language should be detected
  order: [
    "querystring",
    "cookie",
    "localStorage",
    "navigator",
    "htmlTag",
    "path",
    "subdomain",
  ],

  // keys or params to lookup language from
  lookupQuerystring: "lng",
  lookupCookie: "i18next",
  lookupLocalStorage: "i18nextLng",
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  // cache user language on
  caches: ["localStorage", "cookie"],
  excludeCacheFor: ["cimode"], // languages to not persist (cookie, localStorage)

  // optional expire and domain for set cookie
  cookieMinutes: 10,
  cookieDomain: "myDomain",

  // optional htmlTag with lang attribute, the default is:
  htmlTag: document.documentElement,
}

const i18nInit = () =>
  i18next
    // .use(LngDetector)
    .use(initReactI18next)
    .init({
      lng: "es",
      interpolation: { escapeValue: false }, // React already does escaping
      resources: {
        es: {
          common: common_es, // 'common' is our custom namespace
        },
        en: {
          common: common_en,
        },
        pt: {
          common: common_pt,
        },
      },
      detection: options,
      debug: process.env.NODE_ENV === "development",
    })

export default i18nInit
